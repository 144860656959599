



























































































































import { formatAmount } from "@/core";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BankTransferContainer extends Vue {
  @Prop(Boolean) readonly loading!: boolean;

  get totalAmount(): string {
    return formatAmount(this.$store.state.portfolio.totalAmount);
  }
  get cashBalance(): string {
    return formatAmount(this.$store.state.portfolio.cashBalance);
  }
  get totalProfitPercent(): string {
    return this.$store.state.portfolio.profit.percentage;
  }
  get totalProfitAmount(): string {
    return formatAmount(this.$store.state.portfolio.profit.amount);
  }
  get totalCurrentInvestedAmount(): string {
    return formatAmount(this.$store.state.portfolio.totalCurrentInvestedAmount);
  }

  showAddMoneyModal(): void {
    this.$store.dispatch(
      "modal/setComponent",
      () => import("@/components/portfolio/BankTransferModal.vue")
    );
    this.$store.dispatch("modal/show");
  }

  showHelpModal(): void {
    this.$store.dispatch(
      "modal/setComponent",
      () => import("@/components/containers/HowDoWeCalcThisModal.vue")
    );
    this.$store.dispatch("modal/show");
  }

  reloadPage(): void {
    window.location.reload();
  }
}
