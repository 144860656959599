




































































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class YourFavsContainer extends Vue {
  @Prop(Boolean) readonly loading!: boolean;

  get favs(): unknown[] {
    return this.$store.state.portfolio.favorites;
  }
}
