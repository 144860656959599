<template>
  <!-- LOADING STATE -->
  <section v-if="loading" class="flex justify-center items-center p-12">
    <!-- <BaseSpinner></BaseSpinner> -->
  </section>

  <!-- EMPTY STATE NO_CASH_NO_SHARES -->
  <section
    class="bg-white border rounded-sm p-6 md:p-12"
    v-else-if="$store.getters['portfolio/status'] === 'NO_CASH_NO_SHARES'"
  >
    <div>
      <h2 class="font-medium">{{ $t("Portfolio_YourStocks_Title") }}</h2>
    </div>
    <div
      class="
        mt-4
        nn-positions-empty
        py-12
        text-center
        flex flex-col
        justify-center
        items-center
        space-y-4
      "
    >
      <div>
        <img src="@/assets/img/clipboard.svg" alt="" />
      </div>
      <p class="text-gray-500 max-w-xs">
        {{ $t("YourPositions_EmptyState_Text") }}
      </p>
      <p class="font-medium text-lg mt-4">
        {{ $t("YourPositions_EmptyState_CTA") }}
      </p>
    </div>
  </section>

  <!-- EMPTY STATE NO_SHARES -->
  <section
    v-else-if="$store.getters['portfolio/status'] === 'NO_SHARES'"
    class="bg-white border rounded-sm p-6 md:p-12"
  >
    <h2 class="font-medium my-4">{{ $t("Portfolio_YourStocks_Title") }}</h2>

    <div
      class="
        bg-nocashnoshares
        flex flex-col
        items-center
        justify-center
        pb-12
        pt-16
        px-4
      "
    >
      <img width="117" height="96" src="@/assets/img/dog_digging.svg" />
      <div class="max-w-xs text-center">
        <h3 class="text-lg font-medium mt-6">
          {{ $t("Portfolio_YourStocks_Title_NoCashNoShares") }}
        </h3>
        <p class="text-gray-500 mt-6">
          {{ $t("Portfolio_YourStocks_Caption_NoCashNoShares") }}
        </p>
        <p class="text-lg font-medium mt-4">
          {{ $t("Portfolio_YourStocks_CTA_NoCashNoShares") }}
        </p>
      </div>
    </div>
  </section>
  <!-- REGULAR STATE -->
  <section v-else class="border rounded-sm bg-white p-4 py-12">
    <div class="flex justify-between items-center md:px-12">
      <h2 class="font-medium">{{ $t("Portfolio_YourStocks_Title") }}</h2>
      <button
        class="border px-2 py-1 border-blue-500 text-blue-500"
        @click="toggleProfitType"
        v-track:click="{
          label: 'portfolio_yourshares_click_toggleprofits',
          type: profitTypeSymbol,
        }"
      >
        {{ $t("Portfolio_YourShares_ProfitToggle_Button") }}
        {{ profitTypeSymbol }}
      </button>
    </div>
    <div class="mt-4">
      <router-link
        v-for="(i, index) in positions"
        :key="i.asset.assetId"
        :to="'/producto/' + i.asset.assetId"
        class="block md:px-12 hover:bg-gray-50"
      >
        <div
          class="flex items-center py-4"
          :class="{ 'border-b': index !== positions.length - 1 }"
        >
          <div
            class="
              h-8
              w-8
              bg-gray300
              rounded-3xl
              bg-primary-200
              flex
              items-center
              justify-center
              text-sm
              font-medium
              text-center
            "
            v-if="!i.logoURL"
          >
            <small>{{ i.symbol }}</small>
          </div>
          <div v-else class="h-8 w-8 bg-blue-300 rounded-3xl">
            <BaseImg :src="i.logoURL" :alt="i.customName + 'logo'" />
          </div>
          <div class="ml-4">
            <p>{{ i.asset.companyName }}</p>
            <p class="text-gray-500">
              {{ i.shares }} {{ i.shares > 1 ? "acciones" : "acción" }}
            </p>
            <p class="text-gray-400 text-xs flex items-center">
              <donette-chart
                :width="20"
                :height="20"
                :percent="i.portfolioWeight"
                class="mr-2"
              ></donette-chart>
              {{ formatPortfolioWeight(i.portfolioWeight)
              }}{{ $t("Portfolio_YourShares_Asset_AssetWeight") }}
            </p>
          </div>
          <div class="ml-auto text-right">
            <p>
              {{ formatAmount(i.currentAmount.clientCurrency) }}
            </p>
            <div>
              <BasePercent
                v-if="profitType === 'percent'"
                :val="i.profit.percentage.clientCurrency"
              ></BasePercent>
              <BaseAmount
                v-else
                styled
                show-operator
                :amount="i.profit.amount.clientCurrency"
              ></BaseAmount>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { formatAmount } from "@/core/utils";
import DonetteChart from "../charts/DonetteChart";
import { formatNumber } from "@/core/utils/formatNumber";

export default {
  components: { DonetteChart },
  props: {
    loading: Boolean,
  },
  data() {
    return {
      profitType: "percent", // percent or eur
    };
  },
  methods: {
    formatAmount,
    toggleProfitType() {
      if (this.profitType === "percent") this.profitType = "eur";
      else this.profitType = "percent";
    },
    formatPortfolioWeight(am) {
      return formatNumber(+am);
    },
  },
  computed: {
    positions() {
      return this.$store.state.portfolio.positions;
    },
    profitTypeSymbol() {
      const types = {
        percent: "€",
        eur: "%",
      };

      return types[this.profitType];
    },
  },
};
</script>

<style lang="scss" scoped>
.nn-positions-empty {
  background-color: #ebf0e8;
}
.bg-nocashnoshares {
  background-color: #f8eee3;
}
</style>
