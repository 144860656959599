


























import { Component, Vue } from "vue-property-decorator";
import CloseIcon from "vue-material-design-icons/Close.vue";

@Component({
  components: {
    CloseIcon,
  },
})
export default class NewWebtraderBanner extends Vue {
  close(): void {
    this.$emit("close");
  }
}
