import { Doughnut } from "vue-chartjs";
export default {
  extends: Doughnut,
  props: {
    percent: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.renderChart(
      {
        datasets: [
          {
            data: [this.percent, 100 - this.percent],
            backgroundColor: ["rgb(86,130,247)", "rgb(231, 230, 225)"],
          },
        ],
      },
      {
        responsive: true,
        // maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        hover: {
          mode: null,
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
      }
    );
  },
};
