

























import ValueTodayContainer from "@/components/portfolio/ValueTodayContainer.vue";
import YourSharesContainer from "@/components/portfolio/YourSharesContainer.vue";
import YourFavsContainer from "@/components/portfolio/YourFavsContainer.vue";
import NewWebtraderBanner from "@/components/portfolio/NewWebtraderBanner.vue";
import { Component, Vue } from "vue-property-decorator";
import { PortfolioRes } from "@/core/Portfolio/PortfolioUseCases";

@Component({
  components: {
    ValueTodayContainer,
    YourSharesContainer,
    YourFavsContainer,
    NewWebtraderBanner,
  },
})
export default class extends Vue {
  loading = true;
  loadingFavs = true;
  asideHeightStyles = "max-height: 300px;";
  showNewWebBanner = false;

  get portfolio(): PortfolioRes {
    return this.$store.getters["portfolio/portfolio"];
  }

  onCloseNewWebtraderBanner(): void {
    window.localStorage.setItem("new_webtrader_banner", "false");
    this.showNewWebBanner = false;
  }

  async mounted(): Promise<void> {
    this.$store.commit("portfolio/SET_POLLING", true);
    this.loading = true;
    await this.$store.dispatch("portfolio/startPortfolio");
    await this.$store.dispatch("portfolio/fetchFavorites");
    this.loading = false;

    this.showNewWebBanner = window.localStorage.getItem("new_webtrader_banner")
      ? false
      : true;

    // chapuza para el height. sorry. - jordi.
    setTimeout(() => {
      this.asideHeightStyles = `max-height: ${
        document.getElementById("portfolio-main")?.offsetHeight
      }px;`;
      this.loadingFavs = false;
    }, 100);
  }

  beforeDestroy(): void {
    this.$store.commit("portfolio/SET_POLLING", false);
  }
}
